import { ContentPage } from "@/components/pages/content";
import { toggleLanguage } from "@/helper/functions";
import { Meta } from "@/layouts";
import { MainLayout } from "@/layouts/main-layout";
import { contentPage, homeApi } from "@/services";
import { TNextPageWithLayout } from "@/types/layout";
import { collapseActionRender, collapseInit } from "@/utils/CollapseConfig";
import { swiperConfig } from "@/utils/SwiperConfig";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

import { GetStaticProps } from "next";
import Head from 'next/head';
import router from "next/router";
import { useEffect } from "react";
const About: TNextPageWithLayout = (res: any) => {
  useEffect(()=>{
    const galleryItem = document.querySelectorAll(".sec-gallery .gallery .gallery-item") as NodeListOf<HTMLDivElement>
    galleryItem.forEach((item)=>{
      Fancybox.bind(`[data-fancybox="gallery${item.id}"]`, {
        Toolbar: {
          display: {
            left: ["infobar"],
            middle: [],
            right: ["slideshow", "thumbs", "close"],
          },
        },
        on: {
          close: () => {
            const position = window.pageYOffset
            window.scrollTo(0, position);
          },
          destroy: () => {
            const position = window.pageYOffset
            window.scrollTo(0, position);
          }
        }
      });
    })
  }, [res])

  useEffect(() => {
    collapseInit();
    collapseActionRender();
    swiperConfig(".article-type-slide", {
      speed: 600,
      initialSlide: 0,
      centeredSlides: false,
      loop: false,
      spaceBetween: 0,
      effect: "slide",
      slidesPerView: "auto",
    }, "bullets");
  }, [])

  const dataPage = toggleLanguage(res?.res, "content");

  if (dataPage === 'fail' && typeof window !== 'undefined') {
    router.push('404')
  }
  return (
    <>
      <Head>
        <meta name="keywords" content={dataPage?.res?.route?.meta_keywords} />
      </Head>
      <Meta title={dataPage?.res?.route?.title} description={dataPage?.res?.route?.meta_description} canonical={process.env.NEXT_PUBLIC_DEPLOY_URL + dataPage?.res?.route?.url_key}></Meta>
      <ContentPage res={res} />
    </>)
};

export const getStaticPaths = async () => {
  const paths: any[] | void = await homeApi.getListLinks().then((res: any) => res.linksCmsPage)
  return {
    paths: paths?.filter((item) => item.url_key !== 'home')?.map((item: any) => ({ params: { url: item?.url_key } })),
    fallback: true
  }
}

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const handleGetContentCMS = async (language: "vn" | "en") => {
    return await contentPage.getCMSPage(params?.url as any, language).then((res: any) => {
      let htmlContent = res?.route?.content;
      htmlContent = htmlContent?.replaceAll("&lt;", "<");
      htmlContent = htmlContent?.replaceAll("&gt;", ">");
      return { htmlContent, res }
    })
  }

  const contentVN: any = await handleGetContentCMS("vn")
  const contentEN: any = await handleGetContentCMS("en")
  return {
    props: {
      res: {
        content_vn: contentVN?.htmlContent ? contentVN : 'fail',
        content_en: contentEN?.htmlContent ? contentEN : 'fail'
      }
    },
    revalidate: 20,
  }
}
// export const getServerSideProps = async (context: any) => {
//   const { query } = context;

//   let content: any = await contentPage.getCMSPage(query?.url as any).then((res: any) => {

//     let htmlContent = res?.route?.content;
//     htmlContent = htmlContent?.replaceAll("&lt;", "<");
//     htmlContent = htmlContent?.replaceAll("&gt;", ">");
//     return { htmlContent, res }
//   }
//   )

//   return {
//     props: {
//       res: {
//         content: content?.htmlContent ? content : 'fail'
//       }
//     }
//   }
// }

About.Layout = MainLayout;
export default About;
